import React, { Fragment } from "react";
import Ifbr from "./Ifbr";
import logo from "../../images/distrigo_logo.svg";
import moment from "moment";
import {trim} from "lodash";

const Header = ({ customer }) => {
    const {
        postcode,
        address_1,
        address_2,
        address_3,
        address_4,
        address_5,
        primary_contact,
        trade_team_id,
    } = customer;

    let {
        phone,
        email,
        mobile,
    } = customer;

    if(primary_contact) {
        phone = primary_contact.phone;
        email = primary_contact.email;
        mobile = primary_contact.mobile;
    }

    const address = (
        <span className="text-right">
            <Ifbr element={address_1} />
            <Ifbr element={address_2} />
            <Ifbr element={address_3} />
            <Ifbr element={address_4} />
            <Ifbr element={address_5} />
            <Ifbr element={postcode} />
        </span>
    );
    return (
        <Fragment>
            <div className="row column" style={{ marginTop: "2em" }}>
                <img src={logo} alt="Distrigo" style={{ width: "200px" }} />
            </div>
            <div className="row column communication__header" style={{ margin: "0 auto" }}>
                <div className="columns small-4 communication__distrigo">
                    <div className="communication__detail">
                        <div className="details">
                            <p>Date:</p>
                            <span>
                                {moment(new Date()).format("DD/MM/YYYY")}
                            </span>
                        </div>
                        <div className="details">
                            <p>Distrigo Distribution Number:</p>
                            <span>{trade_team_id}</span>
                        </div>
                        <div className="details">
                            <p>DISTRIGO PERKS Activated:</p>
                            <span>
                                {customer.calc.activated_at
                                    ? moment(customer.calc.activated_at).format(
                                        "DD/MM/YYYY"
                                    )
                                    : "-"}
                            </span>
                        </div>
                        <div className="details">
                            <p>DISTRIGO PERKS Status:</p>
                            <span>
                                {customer.calc.perks_status}
                            </span>
                        </div>
                        <div className="details">
                            <p>Permissioned:</p>{" "}
                            <span>
                                {customer.calc.tier_2_activated_user_permission
                                    ? "Yes"
                                    : "No"}
                            </span>
                        </div>
                        <div className="details">
                            <p>Verified:</p>{" "}
                            <span>
                                {customer.calc
                                    .tier_2_activated_user_permission_verified
                                    ? "Yes"
                                    : "No"}
                            </span>
                        </div>
                        <div className="details">
                            <p>Primary Dealer:</p>
                            <span>{customer.dealer}</span>
                        </div>
                    </div>
                </div>
                <div className="columns small-4 communication__distrigo">
                    <div className="communication__detail">
                        <div className="details">
                            <p>Customer Name:</p>
                            <span>{customer.trade_team_customer}</span>
                        </div>
                        <div className="details">
                            <p>Customer Type:</p>
                            <span>{customer.calc.type}</span>
                        </div>
                    </div>
                </div>
                <div className="columns small-4 communication__distrigo">
                    <div className="communication__detail">
                        {primary_contact && primary_contact.first_name && primary_contact.last_name && (
                            <div className="details">
                                <p>Contact Name:</p>
                                <span>{`${primary_contact.first_name} ${primary_contact.last_name}`}</span>
                            </div>
                        )}
                        <div className="details">
                            <p>Address:</p>
                            {address}
                        </div>
                        <div className="details">
                            <p>Phone:</p>
                            <span>{phone || '-'}</span>
                        </div>
                        <div className="details">
                            <p>Email:</p> <span>{email || '-'}</span>
                        </div>
                        <div className="details">
                            <p>Mobile:</p> <span>{mobile || '-'}</span>
                        </div>
                        {(!primary_contact || Object.keys(primary_contact).length === 0) && <div className="details">
                            <p style={{marginTop: "10px"}}>Please note: this customer does not have a primary
                                contact.</p>
                        </div>}
                    </div>
                </div>
                <div className="columns small-4 communication__distrigo"></div>
            </div>
        </Fragment>
    )
};

export default Header;
